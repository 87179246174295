import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResolve } from "react-jpex";
import { gql, useMutation } from "@apollo/client";
import { startAuthentication } from "@simplewebauthn/browser";

import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { AuthenticationService } from "typings/AuthenticationService";
import { Mfa } from "typings/Mfa";
import type { TokenResult } from "typings/TokenResult";
import { UserTopics } from "typings/Topics";

const CONFIRM_MFA = gql`
  mutation ConfirmMfa($mfaCode: String!, $mfaMethod: String!) {
    result: confirmMfa(mfaCode: $mfaCode, mfaMethod: $mfaMethod) {
      accessToken
      refreshToken
    }
  }
`;

export const useConfirmMfa = () => {
  const authenticationService = useResolve<AuthenticationService>(
    "AuthenticationService",
  );
  const { t } = useTranslation();
  const { enqueueMessage } = useMessaging();

  const [errorMethod, setErrorMethod] = useState<Mfa["method"] | null>(null);
  const [mutate, { error, loading, client }] =
    useMutation<TokenResult>(CONFIRM_MFA);

  const handleConfirmMfa = useCallback(
    async (method: Mfa["method"], code: Mfa["code"]) => {
      setErrorMethod(method);

      let mfaCode = code;

      if (method === "webauthn") {
        const response = await startAuthentication(JSON.parse(code));

        mfaCode = JSON.stringify(response);
      }

      const { data } = await mutate({
        variables: { mfaCode, mfaMethod: method },
      });

      if (data?.result) {
        await client.resetStore();

        authenticationService.setAccessToken(data.result.accessToken);
        authenticationService.setRefreshToken(data.result.refreshToken);

        PubSub.publish(UserTopics.MfaConfirmed, { method, code });
        PubSub.publish(UserTopics.LoggedIn);
        enqueueMessage({
          text: t("messaging.mfa.confirmed"),
          variant: "success",
        });
      }
    },
    [authenticationService, client, enqueueMessage, mutate, t],
  );

  return {
    handleConfirmMfa,
    state: { loading, error, errorMethod },
  };
};
