import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { OtpField } from "components/inputs/OtpField";
import { useMessaging } from "contexts/Messaging/useMessagingContext";
import { Mfa } from "typings/Mfa";

export type OtpFormProps = {
  disabled?: boolean;
  error?: boolean;
  onSubmit: (code: Mfa["code"]) => void;
};

export const OtpForm = ({ disabled, error, onSubmit }: OtpFormProps) => {
  const { t } = useTranslation();

  const { enqueueMessage } = useMessaging();

  useEffect(() => {
    if (error) {
      enqueueMessage({
        text: t("mfaCodeError"),
        variant: "error",
      });
    }
  }, [error]);

  return (
    <OtpField
      autoFocus
      allowedCharacters="numeric"
      disabled={disabled}
      invalid={error}
      onSubmit={onSubmit}
    />
  );
};
