import { useEffect } from "react";
import { useResolve } from "react-jpex";
import { useSearchParams } from "react-router-dom";

import { TOKEN } from "constants/queryParams";
import type { UserPreferenceService } from "typings/UserPreferenceService";

export const useCrewInviteCode = () => {
  const preferenceService = useResolve<UserPreferenceService>(
    "UserPreferenceService",
  );

  const [params] = useSearchParams();

  useEffect(() => {
    const code = params.get(TOKEN);

    if (code) {
      preferenceService.setCrewInviteCode(code);
    }
  }, []);
};
